import * as React from "react"
import {GatsbyImage} from 'gatsby-plugin-image'
import {Link} from 'gatsby'
import {Grid} from "@material-ui/core";
import MainMenu from "../MainMenu";
import "./../../styles/index.scss"
import Seo from "../seo";
import "./../../styles/blog_post.scss"
import "./../../styles/arrow.scss"
import "./../../styles/general.scss"
import {slugify} from "../../lib/app_string";
import AppFooter from "../AppFooter";
import {Mixpanel} from "../Mixpanel";
import {useEffect, useMemo} from "react";
import ProfileSecondaryImage from "../ProfileSecondaryImage";
import OpenChat from "../OpenChat";
import {splitBlogArticlePrismic} from "../../lib/structure_data";
import LegalItem from "../LegalItem";
import Banner from "../Banner";
import _ from "lodash";

export default function ComponentBlogPost ({data}) {
    const prismicContact = data.prismicContact
    const homepageData = data.prismicHomepage.data
    const article = data.prismicBlogArticle
    const {legislationItems} = splitBlogArticlePrismic(article);
    const allPrismicBlogArticle = data.allPrismicBlogArticle.edges

    useEffect(() => {
        Mixpanel.track('Page-Blog-Post');
    }, [data])

    const blogUrl = 'https://www.cabinet-psiho.ro/blogpost/' + slugify(article.data.article_title.text) + "/";

    const renderJsonLd = () => {
        return {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": article.data.article_title.text,
            "alternativeHeadline": article.data.article_title.text,
            "image": article.data.article_main_image.gatsbyImageData.images.fallback.src,
            "editor": "Roxana Simionescu",
            "genre": "Cabinet psihologie Roxana Simionescu - Psiholog atestat, sector 6",
            "keywords": "Cabinet psihologie Roxana Simionescu - Psiholog atestat, sector 6",
            "wordcount": article.data.article_description.text.split(" ").length,
            "publisher": "Cabinet psihologie Roxana Simionescu - Psiholog atestat, sector 6",
            "url": blogUrl,
            "datePublished": article.first_publication_date,
            "dateCreated": article.first_publication_date,
            "dateModified": article.last_publication_date,
            "description": article.data.article_seo_description.text,
            "articleBody": article.data.article_description.text,
            "author": {
                "@type": "Person",
                "name": "Roxana Simionescu - psiholog",
                "url": "https://www.facebook.com/people/Psiholog-Roxana-Anca-Simionescu/100049702071513/"
            }
        }
    }

    const parseDirtyHtml = (dirtyString) => {
        // const parser = new DOMParser();
        // const document = parser.parseFromString(dirtyString, 'text/html');
        // return document.textContent || '';
        return dirtyString
    }

    function randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const seoData = {
        canonical: "https://www.cabinet-psiho.ro/blogpost/" + slugify(article.data.article_title.text) + "/",
        title: article.data.article_title.text + '- Roxana S. psiholog atestat Bucuresti',
        description: article.data.article_seo_description.text,
        image: article.data.article_main_image.gatsbyImageData.images.fallback.src,
        url: blogUrl,
        metas: [
        ]
    }

    let indexes = _.uniq([randomInteger(0, allPrismicBlogArticle.length), randomInteger(0, allPrismicBlogArticle.length), randomInteger(0, allPrismicBlogArticle.length)]);

    const renderUsingMemo = () => (
        <div >
            <Seo title={article.data.article_title.text} seoData={seoData}/>

            <script
                type="application/ld+json"
                nonce={"mjkljkl78988KHJKLKkhkljkldsjgjkjjhjhjbhijsvgkjhoirfujkk"}
                dangerouslySetInnerHTML={{ __html: JSON.stringify(renderJsonLd()) }}
            />

            <Grid container>
                <MainMenu pageName={"blog"} prismicContact={prismicContact} />

                <Banner />

                <Grid item sm={12} xl={12} lg={12} md={12} className={"blog_post_container"}>
                    <div className={"w-full flex flex-column justify-content-center align-items-center align-middle"}>
                        <div className={"w-full flex flex-column justify-content-center align-middle align-items-center blog_item"}>

                            <div className={"w-full flex flex-row justify-content-center align-middle align-items-center bck_green_light"} >
                                <GatsbyImage image={article.data.article_main_image.gatsbyImageData} className={"blog_item_image"} alt={"Testimonial image"}/>
                            </div>
                            <div className={"blog_title font-bold m-4"}>
                                <Link to={"/blogpost/" + slugify(article.data.article_title.text)}>
                                    {article.data.article_title.text}
                                </Link>
                            </div>

                            <div className={"flex flex-row w-full justify-items-start align-middle align-content-start"}>
                                <a href={"/blog"} className={"font_size_xl"} >
                                    <div className={"w-full flex flex-row justify-content-start align-items-center align-middle"}>
                                        <div>
                                            <span className="arrow arrow-bar is-left"></span>
                                        </div>
                                        <div>
                                            <i>Blog</i>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div
                                className={"m-4"}
                                dangerouslySetInnerHTML={{__html: article.data.article_description.html}}
                            >
                            </div>

                            {legislationItems.items && legislationItems.items.length && (
                                <React.Fragment>
                                    <div className={"w-full flex flex-col justify-content-center align-items-center align-middle font_size_xl"}>
                                        Legislatie
                                    </div>
                                    <div className={"w-full flex flex-col justify-content-start align-items-start align-middle mt-8"}>
                                        {legislationItems.items.map(legislationItem => (
                                            <LegalItem url={legislationItem.link.raw[0].text} text={legislationItem.text.text} />
                                        ))}
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>



                    <div className={"w-full flex flex-row justify-content-center align-items-center align-middle mt-16"}>
                        <OpenChat />
                    </div>

                    <div className={"w-full flex flex-row justify-content-center align-items-center align-middle mt-16 mb-16"}>
                        <ProfileSecondaryImage width={70}/>
                        <div className={"ml-8"}>Autor:&nbsp;&nbsp;&nbsp;&nbsp;<b>{homepageData.header_my_name.text}</b>, Psiholog, Terapeut, Dezvoltare Personala</div>
                    </div>
                </Grid>

                <div className={"flex flex-row flex-wrap justify-center align-middle align-content-center mb-16 mt-16"}>
                        {allPrismicBlogArticle.map((article, k) => {
                            if (indexes.indexOf(k) === -1) {
                                return <></>
                            }
                            return (
                                <div className={"mr-4 ml-7 flex flex-col"}>
                                    <div>
                                        <GatsbyImage image={article.node.data.article_main_image.gatsbyImageData}
                                                     alt={article.node.data.article_title.text}
                                                     imgStyle={{width: '500px', height: "auto", margin: '10px', cursor:'pointer', maxWidth: '500px'}}
                                        />
                                    </div>
                                    <div className={"flex flex-row justify-content-start align-start align-items-start"}>
                                        <div className={"blog_title font-bold maximum_box_size"}>
                                            <Link to={"/blogpost/" + slugify(article.node.data.article_title.text)}>
                                                {article.node.data.article_title.text}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                </div>

                <AppFooter prismicContact={prismicContact}/>
            </Grid>
        </div>
    )

    const memoizedValue = useMemo(() => renderUsingMemo(), [homepageData]);
    return (<>{memoizedValue}</>)
}

