import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"


export default function ProfileSecondaryImage(props) {

    const width = props.width || 350;

    return (
        <StaticImage
            // src={'../images/presentation/_MG_custom_01-min-01.png'}
            src={'../images/opt/mobile_MG_custom_01-min-01.jpg'}
            alt={"presentation image"}
            style={{'width': width + 'px', height: 'auto', borderRadius: '20px'}}
        />
    )
}
